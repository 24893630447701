import { render, staticRenderFns } from "./questionDialog.vue?vue&type=template&id=31783f83&scoped=true&"
import script from "./questionDialog.vue?vue&type=script&lang=js&"
export * from "./questionDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31783f83",
  null
  
)

export default component.exports